<template>
  <div class="team-hero">
    <div v-if="!isLoading">
      <!-- top -->
      <div
        class="team-background"
        :style="{ 'background-image': `url(${team.background_image})` }"
      >
        <div class="team-actions" v-if="!readOnly">
          <div
            class="actions-back cursor-pointer"
            @click="$router.push({ name: 'teamsList' })"
          >
            <i class="material-icons">arrow_back_ios</i>
            <span>{{ $t("back") }}</span>
          </div>
          <div class="actions-buttons">
            <mem-button
              v-if="owner || captain"
              :btn-type="'secondary-dark'"
              @click="
                $router.push({ name: 'teamEdit', params: { id: team.slug } })
              "
              >{{ $t("edit") }}</mem-button
            >
            <mem-button
              v-if="showInvite"
              :btn-type="'secondary-dark'"
              @click="showInviteDialog = true"
              >{{ $t("invite") }}</mem-button
            >
            <template>
              <v-dialog
                v-model="showInviteDialog"
                overlay-color="#000000"
                overlay-opacity="0.54"
                :max-width="480"
                light
                class="mem-dialog"
              >
                <invite-dialog
                  :inviteLink="teamInviteLink"
                  @close-invite="showInviteDialog = false"
                />
              </v-dialog>
            </template>
            <mem-button
              v-if="team.is_public && guest"
              :btn-type="'secondary-dark'"
              @click="join"
            >
              {{ $t("join") }}
            </mem-button>
            <div
              class="member-btn cursor-pointer"
              v-if="!owner && member"
              @click.stop="showLeaveDialog = true"
            >
              <i class="material-icons"> how_to_reg </i>
            </div>
            <template>
              <v-dialog
                v-model="showLeaveDialog"
                overlay-color="#000000"
                overlay-opacity="0.54"
                :max-width="410"
                class="mem-dialog"
              >
                <leave-dialog
                  :team="team"
                  :canLeave="true"
                  v-on:hide-dialog="showLeaveDialog = false"
                >
                  <div>
                    <mem-button
                      :btn-type="'tertiary-light'"
                      @click="showLeaveDialog = false"
                      >{{ $t("cancel") }}</mem-button
                    >
                    <mem-button @click="leave">
                      {{ $t("leave") }}
                    </mem-button>
                  </div>
                </leave-dialog>
              </v-dialog>
            </template>
          </div>
        </div>
        <div
          class="change-background"
          v-if="(owner && !readOnly) || (captain && !readOnly)"
        >
          <div class="upload-button">
            <label class="material-icons" for="team-background">
              camera_alt
            </label>
            <input
              ref="teamBackground"
              id="team-background"
              type="file"
              name="team background"
              accept="image/jpeg, image/png"
              @change="handleFileUpload('background_image', 2.79 / 1)"
            />
          </div>
        </div>
      </div>
      <!-- bottom -->
      <div class="team-info">
        <div class="team-avatar">
          <label for="team-avatar" v-if="team.avatar" class="avatar-pic">
            <img :src="team.avatar" alt="team avatar" />
          </label>
          <label for="team-avatar" v-if="!team.avatar" class="defaul-avatar">
            <img
              src="./../../../assets/team-card-avatar.svg"
              alt="team avatar"
            />
          </label>
          <input
            ref="teamAvatar"
            id="team-avatar"
            type="file"
            name="team avatar"
            accept="image/jpeg, image/png"
            @change="handleFileUpload('avatar', 1 / 1)"
            :disabled="!owner && !captain"
          />
          <template>
            <v-dialog
              v-model="showUploadDialog"
              overlay-color="#000000"
              overlay-opacity="0.54"
              :max-width="582"
              class="mem-dialog"
            >
              <crop-dialog
                :imageSrc="fileSrc"
                :options="dialogOptions"
                :teamId="team.id"
                v-on:close-modal="closeDialog"
              >
                <div></div>
              </crop-dialog>
            </v-dialog>
          </template>
        </div>
        <div class="mem-font--hero-block__title team-name">
          <div class="text">{{ team.name }}</div>
          <i class="material-icons" v-if="team.is_public && !readOnly">
            public
          </i>
          <i class="material-icons" v-if="!team.is_public && !readOnly">
            lock
          </i>
        </div>
        <div class="team-description" v-if="team.description">
          <div class="text">{{ team.description }}</div>
        </div>
        <div
          class="social-links"
          v-if="!readOnly && (team.facebook_link || team.instagram_link)"
        >
          <a :href="fbLink" target="_blank" v-if="team.facebook_link">
            <i class="material-icons cursor-pointer"> facebook </i>
          </a>
          <a :href="instLink" target="_blank" v-if="team.instagram_link">
            <img
              class="cursor-pointer"
              src="../../../assets/instagram_icon.svg"
              alt="team inst"
            />
          </a>
        </div>
        <div class="info-footer" v-if="!readOnly">
          <div class="team-location">
            <div class="text">
              <div>{{ team.location }}</div>
              <div v-if="team.location" class="text-divider">•</div>
              <div>{{ team.members_count + " " + $t("members") }}</div>
            </div>
            <v-tooltip bottom color="#181818" z-index="5">
              <template v-slot:activator="{ on, attrs }">
                <i
                  class="material-icons-round cursor-pointer"
                  v-if="guest"
                  v-on="on"
                  v-bind="attrs"
                  @click.stop="showReportDialog = true"
                >
                  report_problem
                </i>
              </template>
              <span>{{ $t("report_team_dialog_title") }}</span>
            </v-tooltip>
            <template>
              <v-dialog
                v-model="showReportDialog"
                overlay-color="#000000"
                overlay-opacity="0.54"
                :max-width="395"
                light
                class="mem-dialog"
              >
                <report-dialog
                  v-on:close-report="showReportDialog = false"
                  :team="team"
                  v-if="showReportDialog"
                ></report-dialog>
              </v-dialog>
            </template>
          </div>
          <div class="updates-switcher" v-if="owner || member">
            <div>{{ $t("get_updates") }}</div>
            <div class="toggleWrapper">
              <input
                type="checkbox"
                name="toggle1"
                class="mobileToggle"
                id="toggle1"
                :checked="team.notifications"
                v-on:input="update"
              />
              <label for="toggle1"></label>
            </div>
          </div>
        </div>
        <div class="join-buttons" v-if="readOnly">
          <mem-button :btn-type="'tertiary-dark'" @click="goToTeamView">
            {{ $t("cancel") }}
          </mem-button>
          <mem-button @click="acceptInvite">
            {{ $t("join") }}
          </mem-button>
        </div>
      </div>
    </div>
    <!-- skeleton loader -->
    <div v-if="isLoading" class="team-loading">
      <div class="back-btn" @click="$router.push({ name: 'teamsList' })">
        <i class="material-icons">arrow_back_ios</i>
        <span>{{ $t("back") }}</span>
      </div>
      <div class="component-loading">
        <div class="loading-line line-top">
          <div class="animated"></div>
        </div>
        <div class="loading-line line-bottom">
          <div class="animated"></div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import UploadImageDialog from "./UploadImageDialog.vue";
import LeaveTeamDialog from "./LeaveTeamDialog.vue";
import ReportTeamDialog from "./ReportTeamDialog.vue";
import InviteTeamDialog from "./InviteTeamDialog.vue";
import { mapActions } from "vuex";
export default {
  data: () => ({
    file: null,
    fileSrc: null,
    showUploadDialog: false,
    showLeaveDialog: false,
    showReportDialog: false,
    showInviteDialog: false,
    dialogOptions: {},
  }),
  props: ["team", "display", "readOnly", "isLoading"],
  computed: {
    // roles
    owner() {
      return this.display === "owner";
    },
    captain() {
      return this.display === "captain";
    },
    member() {
      return this.display === "member";
    },
    guest() {
      return this.display === "guest";
    },
    //
    showInvite() {
      return this.owner || this.captain || this.member;
    },
    fbLink() {
      if (this.team?.facebook_link?.includes("http"))
        return this.team.facebook_link;
      return `//${this.team.facebook_link}`;
    },
    instLink() {
      if (this.team?.instagram_link?.includes("http"))
        return this.team.instagram_link;
      return `//${this.team.instagram_link}`;
    },
    teamInviteLink() {
      return `${window.location.origin}/team_invite/${this.team.invite_code}`;
    },
  },
  components: {
    "mem-button": () => import("@/components/base/BaseButton.vue"),
    "crop-dialog": UploadImageDialog,
    "leave-dialog": LeaveTeamDialog,
    "report-dialog": ReportTeamDialog,
    "invite-dialog": InviteTeamDialog,
  },
  methods: {
    ...mapActions([
      "handleTeamNotifications",
      "joinTeam",
      "leaveTeam",
      "getTeamMembers",
    ]),
    async update() {
      await this.handleTeamNotifications({
        teamId: this.team.id,
        data: { notifications: !this.team.notifications },
      });
    },
    handleFileUpload(type, aspectRatio) {
      this.dialogOptions = { type, aspectRatio };

      // this.errorDialog = false;

      if (type === "avatar") this.file = this.$refs.teamAvatar.files[0];
      if (type === "background_image")
        this.file = this.$refs.teamBackground.files[0];

      if (this.file) {
        this.fileSrc = URL.createObjectURL(this.file);
        this.showUploadDialog = true;
      }
    },
    closeDialog() {
      this.$refs.teamAvatar.value = "";
      this.$refs.teamBackground.value = "";

      this.showUploadDialog = false;
    },
    async join() {
      console.log("Joing...");
      await this.joinTeam({ teamId: this.team.slug });
      if (!this.readOnly)
        await this.getTeamMembers({
          teamId: this.team.id,
          queryType: "default",
        });
    },
    async leave() {
      console.log("Leaving...");
      this.showLeaveDialog = false;
      await this.leaveTeam(this.team.slug);
      if (!this.readOnly)
        await this.getTeamMembers({
          teamId: this.team.id,
          queryType: "default",
        });
    },
    goToTeamView() {
      this.$router.push({
        name: "teamLeaderboard",
        params: { id: this.team.slug },
      });
    },
    async acceptInvite() {
      let teamParams = {
        teamId: this.team.slug,
      };
      if (!this.team.is_public)
        teamParams.inviteCode = this.$router.currentRoute.params.inviteCode;
      await this.joinTeam(teamParams);
      this.goToTeamView();
    },
  },
  mounted() {},
};
</script>
<style lang="scss" scoped>
::v-deep(.v-tooltip__content) {
  border-radius: 0;
  padding: 10px;
  text-transform: capitalize;
  font-size: 12px;
  line-height: 15px;
  font-weight: 600;
  letter-spacing: 0.02em;
}
.team-hero {
  @mixin textSmall {
    font-size: 12px;
    text-transform: uppercase;
    line-height: 14px;
  }
  @mixin fileInputLabel {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    outline: none;
    height: 100%;
    width: 100%;
  }
  @mixin backButton {
    display: flex;
    flex-direction: row;
    align-items: center;

    font-size: 14px;
    font-weight: bold;
    line-height: 14px;
    text-transform: uppercase;

    i {
      font-size: 16px;
      margin-right: 6px;
    }
    @media screen and (max-width: 499px) {
      display: none;
    }
  }
  .team-background {
    background-size: cover;
    height: 240px;
    padding: 40px 30px 30px 30px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: relative;

    &:before {
      content: "";
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      z-index: 0;
      background: rgba(0, 0, 0, 0.6);
    }

    .team-actions {
      display: flex;
      flex-flow: row;
      justify-content: space-between;
      padding-left: 10px;
      z-index: 10;
      .actions-back {
        display: flex;
        flex-direction: row;
        align-items: center;

        font-size: 14px;
        font-weight: bold;
        line-height: 14px;
        text-transform: uppercase;

        i {
          font-size: 16px;
          margin-right: 6px;
        }
        @media screen and (max-width: 499px) {
          display: none;
        }
      }
      .actions-buttons {
        display: flex;
        flex-direction: row;
        .member-btn {
          margin-left: 12px;
          height: 36px;
          width: 36px;
          border-radius: 100px;
          background-color: #ffffff;
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: center;
          i {
            color: #000000;
            font-size: 28px;
          }
        }
        button {
          margin-left: 12px;
          height: 36px;

          font-size: 12px;
          font-weight: bold;
          line-height: 15px;
        }
      }
      @media screen and (max-width: 499px) {
        justify-content: flex-end;
      }
    }
    .change-background {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      z-index: 10;
      .upload-button {
        height: 44px;
        width: 44px;
        background-color: #ffffff;
        border-radius: 100px;
        color: #140d0c;
        input[type="file"] {
          display: none;
        }
        label {
          font-size: 20px;
          @include fileInputLabel;
        }
      }
    }
    @media screen and (max-width: 499px) {
      padding: 20px 16px;
      background-position-x: 50%;
    }
  }
  .team-info {
    position: relative;
    padding: 40px 30px 26px 30px;
    background: #121212;
    .team-avatar {
      position: absolute;
      height: 68px;
      width: 68px;
      background: #ffffff;
      top: -38px;
      left: 30px;
      border-radius: 100px;
      z-index: 10;
      @media screen and (max-width: 499px) {
        left: 16px;
      }
      input[type="file"] {
        display: none;
      }
      .avatar-pic {
        display: flex;
        cursor: pointer;
        outline: none;
        img {
          border-radius: 100px;
          height: 68px;
          width: 68px;
        }
      }
      .defaul-avatar {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        height: 100%;
        width: 100%;
        cursor: pointer;
        outline: none;
        i {
          font-size: 30px;
          color: #000000;
        }
      }
    }
    .team-name {
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      justify-content: space-between;
      margin-bottom: 18px;
      @media screen and (max-width: 499px) {
        margin-bottom: 16px;
        i {
          line-height: 31px;
        }
      }
      .text {
        overflow: hidden;
        text-overflow: ellipsis;
        // need to test in firefox browsers
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
      }
      i {
        color: #595959;
        padding-left: 20px;
        line-height: 35px;
      }
    }
    .team-description {
      margin-bottom: 20px;
      max-width: 66%;
      .text {
        font-size: 14px;
        line-height: 19px;
      }
      @media screen and (max-width: 499px) {
        margin-bottom: 15px;
        max-width: 100%;
        padding-right: 34px;
      }
    }
    .social-links {
      display: flex;
      a {
        text-decoration: none;
        display: flex;
      }
      i {
        font-size: 20px;
        margin-right: 8px;
      }
    }
    .info-footer {
      display: flex;
      flex-direction: row;
      justify-content: space-between;

      margin-top: 16px;

      .team-location {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        flex-grow: 1;
        // width: 100%;
        .text {
          display: flex;
          flex-direction: row;
          align-items: center;

          font-size: 12px;
          line-height: 14px;
          text-transform: uppercase;
          .text-divider {
            margin: 0 3px;
          }
        }
        i {
          color: #595959;
        }
        i:hover {
          color: #ffffff;
        }
      }
      .updates-switcher {
        display: none;

        // display: flex;
        flex-direction: row;
        align-items: center;

        @include textSmall;

        @mixin inactiveMixin {
          content: "";
          position: absolute;
          display: block;
        }

        @mixin beforeAnimation {
          transition: 0.2s cubic-bezier(0.24, 0, 0.5, 1);
        }

        @mixin afterAnimation {
          box-shadow: 0 0 0 1px hsla(0, 0%, 0%, 0.1),
            0 4px 0px 0 hsla(0, 0%, 0%, 0.04), 0 4px 9px hsla(0, 0%, 0%, 0.13),
            0 3px 3px hsla(0, 0%, 0%, 0.05);
          transition: 0.35s cubic-bezier(0.54, 1.6, 0.5, 1);
        }

        // Mobile Toggle Switch
        .toggleWrapper {
          background: inherit;
          margin-left: 14px;
          input {
            &.mobileToggle {
              opacity: 0; // hides checkbox
              position: absolute;
              & + label {
                position: relative;
                display: inline-block;
                user-select: none;
                transition: 0.4s ease;
                height: 30px;
                width: 50px;
                // border: 1px solid #e4e4e4;
                border-radius: 60px;
                &:before {
                  @include inactiveMixin;
                  @include beforeAnimation;
                  height: 30px;
                  width: 51px;
                  top: 0;
                  left: 0;
                  border-radius: 30px;
                  background: #aaaaaa;
                }
                &:after {
                  @include inactiveMixin;
                  @include afterAnimation;
                  background: #ffffff;
                  height: 28px;
                  width: 28px;
                  top: 1px;
                  left: 0;
                  border-radius: 60px;
                }
              }
              // When Active
              &:checked {
                & + label:before {
                  background: #cf1019; // Active Color
                  transition: width 0.2s cubic-bezier(0, 0, 0, 0.1);
                }
                & + label:after {
                  left: 54px - 30px;
                }
              }
            }
          }
        }
        @media screen and (max-width: 499px) {
          justify-content: space-between;
          padding-top: 16px;
          margin-top: 16px;
          border-top: 1px solid #414141;
          font-size: 14px;
          line-height: 17px;
          font-weight: bold;
        }
      }
      @media screen and (max-width: 499px) {
        flex-direction: column;
      }
    }
    .join-buttons {
      margin-top: 40px;
      padding-bottom: 14px;
      @media screen and (max-width: 499px) {
        margin-top: 36px;
        padding-bottom: 22px;
      }
      .tertiary-dark {
        margin-right: 12px;
      }
      button {
        min-width: 112px;
      }
    }
    @media screen and (max-width: 499px) {
      padding: 50px 16px 20px 16px;
    }
  }
  .team-loading {
    padding: 40px 0 108px 30px;
    background: #090909;
    @media screen and (max-width: 499px) {
      padding: 288px 16px;
    }
    .back-btn {
      @include backButton;
      @include cursorPointer;
      padding: 10px 0 10px 10px;
      @media screen and (max-width: 499px) {
        display: none;
      }
    }
    .component-loading {
      display: flex;
      flex-direction: column;
      overflow: hidden;
      padding-top: 42px;
      margin-top: 166px;
      @media screen and (max-width: 499px) {
        padding-top: 0;
        margin-top: 0;
      }
      .loading-line {
        height: 40px;
        background-color: #090909;
        position: relative;
        overflow: hidden;
        @media screen and (max-width: 499px) {
          height: 26px;
        }
        .animated {
          @include animatedLine;
        }
      }
      .line-top {
        margin-bottom: 12px;
        width: 400px;
        @media screen and (max-width: 499px) {
          margin-bottom: 6px;
          width: 242px;
        }
      }
      .line-bottom {
        width: 250px;
        @media screen and (max-width: 499px) {
          width: 134px;
        }
      }
    }
  }
}
</style>
